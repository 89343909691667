import React, { useEffect, useState } from "react";

const CoinMarketCapWidget = () => {
  const [loading, setLoading] = useState(true);
  const [inflating, setInflating] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://files.coinmarketcap.com/static/widget/coinMarquee.js";
    script.async = true;

    script.onload = () => {
      setLoading(false);

      // Wait for the widget to fully render
      setTimeout(() => {
        setInflating(true);

        const prices = document.querySelectorAll(".coin-marquee-item-price");

        prices.forEach((priceElement) => {
          const rawText = priceElement.textContent.replace("₹", "").trim(); // Remove ₹ and trim whitespace
          const extractedPrice = parseFloat(rawText.replace(/[^0-9.]/g, "")); // Extract numeric value

          if (!isNaN(extractedPrice)) {
            // Add 8% to the price
            const inflatedPrice = extractedPrice * 1.08;
            // Format the inflated price
            const formattedPrice = `₹${inflatedPrice.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            })}`;
            // Update the price in the DOM
            priceElement.textContent = formattedPrice;
          }
        });

        setInflating(false);
      }, 1000); // Adjust timing as needed for widget rendering
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {(loading || inflating) && <div className="loader">Loading...</div>}
      <div
        id="coinmarketcap-widget-marquee"
        coins="1,1027,825,5426,1839,3408,5994,8916,52,74,2010,1958,20947,1975,11419,512,1831,24478,2,6535,4642"
        currency="INR"
        theme="light"
        transparent="false"
        show-symbol-logo="true"
      ></div>
    </div>
  );
};

export default CoinMarketCapWidget;


export const BASE_URL = `https://first-dex-be.ammag.tech/api/auth`;
export const ActiveSections = {
    LANDING: 0,
    // LIVE_PRICE: 1,
    FIRST_DEX_ACCOUNT: 1,
    LARGEST_DEX: 2,
    SWAP_CRYPTO: 3,
    UNIT_DIGITAL: 4,
    ABOUT_PANEL: 5,
    HASSEL_FREE_CRYPTO: 6,
    GET_IN_TOUCH: 7,
  };
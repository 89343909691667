import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footerlog from "../Images/Logo/footer-logo.svg";
import IconFb from "../Images/Icons/icon-facebook.png";
import IconInstagram from "../Images/Icons/icon-instagram.png";
import IconLinkedin from "../Images/Icons/icon-linkedin.png";
import IconX from "../Images/Icons/icon-x.png";
import IconYoutube from "../Images/Icons/icon-youtube.png";
function Footer() {
  return (
    <>
      <Container className="footer" fluid>
        <Container>
          <Row>
            <Col xl="12" lg="12" md="12">
              <Image className="footer-logo" src={Footerlog} alt="Logo" />
              <ul className="footer-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a href="/#FirstDexAcoount">About</a>
                </li>
                <li>
                  <Link to="/Faq">FAQs</Link>
                </li>
                <li>
                  <a href="/#GetInTouch">Tutorial Videos </a>
                </li>
              </ul>
              <ul className="social-media-list">
                <li>
                  <Link target="_blank" to="https://www.facebook.com/">
                    <Image src={IconFb} alt="Facebook" />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://www.instagram.com/">
                    <Image src={IconInstagram} alt="Instagram" />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://www.youtube.com/">
                    <Image src={IconYoutube} alt="Youtbe" />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://x.com/">
                    <Image src={IconX} alt="X" />
                  </Link>
                </li>
              </ul>
              <p>©Copyright 2025 FirstDex</p>
              <ul className="Privacy-list">
                <li>
                  <Link to="/PrivacyPolicy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/TermsConditions">Terms & Conditions</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
export default Footer;

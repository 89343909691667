import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Scss/App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import Faq from "./Pages/Faq";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions";
function NotFound() {
  return (
    <div className="pagenotfound">
      <h1>404 - Page Not Found</h1>
    </div>
  );
}

function Router() {
  const [showFooter, setShowFooter] = useState(true);
  const [activeSection, setActiveSection] = useState(0); // Track active section
  const location = useLocation(); // Get the current location

  // Determine if we're on the PrivacyPolicy or TermsConditions page
  const isPrivacyOrTerms =
    location.pathname === "/PrivacyPolicy" || location.pathname === "/TermsConditions" || location.pathname === "/Faq";

  return (
    <>
      <NavBar
        setActiveSection={setActiveSection}
        activeSection={activeSection} // Pass activeSection here
        className={isPrivacyOrTerms ? "navbar-special" : ""}
      />
      <Routes>
        <Route path="/" element={<Home setShowFooter={setShowFooter} activeSection={activeSection} setActiveSection={setActiveSection} />} />
        <Route path="/Faq" element={<Faq />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsConditions" element={<TermsConditions />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {showFooter && <Footer />}
    </>
  );
}

export default Router;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Herobg from "../Images/Bg/header-bg.png";
function InnerHeader() {
  const Navigation = useNavigate();
  return (
    <>
      <Container className="inner-header" fluid>
        <div className="bg-layer" style={{ backgroundImage: `url(${Herobg})` }} />
        <Container>
          <Row>
            <Col xl="12" lg="12">
              <h1>{
                window.location.pathname == "/PrivacyPolicy" ? "Privacy Policy" :
                  window.location.pathname == "/TermsConditions" ? "Terms & Conditions" :
                    window.location.pathname == "/Faq" ? "FAQs" :
                      ""}</h1>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
export default InnerHeader;

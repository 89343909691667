import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerHeader from "../Components/InnerHeader";
function TermsConditions() {
  return (
    <>
      {/* Inner Header */}
      <InnerHeader />
      {/* Inner Header */}

      <Container className="inner-main" fluid>

        <Container>
          <Row>
            <Col xl="12" lg="12" className="inner-content">
              <h3>1. Definitions </h3>
              <p><b>1.1 “FirstDex”</b> refers to its platform, and services. </p>
              <p><b>1.2 “User”</b> refers to any individual or entity accessing or using FirstDex.</p>
              <p><b>1.3 “Services”</b> refer to all functionalities provided by FirstDex, including but not limited to trading, staking, cross-chain swaps, deposits, and withdrawals.</p>
              <p><b>1.4 “KYC”</b>   refers to Know Your Customer verification, which includes identity and address verification.</p>
              <p><b>1.5 “AML” </b> refers to Anti-Money Laundering regulations as mandated by the Prevention of Money Laundering Act, 2002 (PMLA) and related laws.</p>
              <h3>2. Eligibility </h3>
              <p><b>2.1 </b> Users must be at least 18 years old to use FirstDex.</p>
              <p><b>2.2</b>  Users must complete the KYC verification process before accessing certain services.</p>
              <p><b>2.3</b>  By using the platform, you confirm that you are not a resident or national of any restricted jurisdiction as per Indian laws or international sanctions.</p>
              <h3>3. User Obligations </h3>
              <p><b>3.1</b> KYC Compliance:</p>
              <ul className="dot-list">
                <li>Users are required to provide accurate, up-to-date, and complete information during the KYC process.</li>
                <li>FirstDex reserves the right to suspend or terminate accounts if KYC requirements are not fulfilled.</li>
              </ul>
              <p><b>3.2</b>  AML Compliance:</p>
              <ul className="dot-list">
                <li>Users must ensure that funds deposited or traded on FirstDex do not originate from illegal activities.</li>
                <li>Suspicious transactions may be reported to the FIU-India as mandated by Indian law.</li>
              </ul>
              <h3>3.3 Prohibited Activities: </h3>
              <p>Users agree not to engage in the following:</p>
              <ul className="dot-list">
                <li>Money laundering, terrorist financing, or any other illegal activity.</li>
                <li>Using the platform for fraudulent or malicious purposes.</li>
                <li>Violating any applicable laws or regulations. </li>
              </ul>
              <h3>4. Account Management </h3>
              <p><b>4.1</b>  Account Security:</p>
              <p>Users are responsible for safeguarding their private keys, wallets, and account credentials.</p>
              <p><b>4.2 </b> Account Suspension or Termination:</p>
              <p>FirstDex reserves the right to suspend or terminate accounts in cases of:</p>
              <ul className="dot-list">
                <li>Suspected fraudulent or illegal activity.</li>
                <li>Violation of these Terms and Conditions.</li>
                <li>Non-compliance with KYC or AML requirements.</li>
              </ul>
              <h3>5. Fees and Transactions </h3>
              <p><b>5.1</b>  Transaction fees will be transparently displayed before execution.</p>
              <p><b>5.2 </b> Users acknowledge that FirstDex cannot reverse blockchain transactions once completed.</p>
              <h3>6. Privacy Policy </h3>
              <p><b>6.1 </b> All personal data collected during the KYC process is handled in accordance with our Privacy Policy and applicable Indian data protection laws.</p>
              <p><b>6.2</b>  Information may be shared with law enforcement or regulatory authorities as required by law.</p>
              <h3>7. Regulatory Compliance </h3>
              <p><b>7.1 </b> FirstDex complies with all applicable Indian laws, including the PMLA, and is registered with the FIU-India.</p>
              <p><b>7.2</b> Users agree to cooperate with any requests for information or documentation to ensure regulatory compliance.</p>
              <h3>8. Risk Disclosure </h3>
              <p><b>8.1 </b>Cryptocurrencies and blockchain technologies involve significant risks, including but not limited to market volatility and loss of funds.</p>
              <p><b>8.2 </b>Users acknowledge and accept these risks when using FirstDex.</p>
              <h3>9. Limitation of Liability </h3>
              <p><b>9.1 </b> FirstDex is not liable for:</p>
              <ul className="dot-list">
                <li>Losses incurred due to user errors, including mismanagement of private keys.</li>
                <li>Losses resulting from technical issues, including blockchain congestion or downtime.</li>
                <li>Losses due to third-party hacks or security breaches outside of FirstDex’s control.</li>
              </ul>
              <h3>10. Dispute Resolution </h3>
              <p><b>10.1</b>  Any disputes arising from these Terms and Conditions will be governed by Indian law.</p>
              <p><b>10.2 </b> Disputes will be subject to arbitration under the Arbitration and Conciliation Act, 1996, with the venue in [Insert City], India.</p>
              <h3>11. Amendments </h3>
              <p><b>11.1</b>  FirstDex reserves the right to amend these Terms and Conditions at any time.</p>
              <p><b> 11.2</b> Changes will be communicated to users via email or platform notifications. Continued use of the platform constitutes acceptance of the revised terms.</p>
              <h3>12. Contact Information </h3>
              <p>For any questions or concerns, contact us at: </p>
              <p> <b>Email:</b> <Link to="mailto:support@firstdex.in" target="_blank">support@firstdex.in</Link> </p>
              <p>By using <b>FirstDex,</b> you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>
            </Col>
          </Row>
        </Container>

      </Container>
    </>
  );
}
export default TermsConditions;

import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import InnerHeader from "../Components/InnerHeader";
function Faq() {
    return (
        <>
            {/* Inner Header */}
            <InnerHeader />
            {/* Inner Header */}

            <Container className="inner-main" fluid>
                <Container>
                    <Row>
                        <Col xl="12" lg="12" className="inner-content faq-page">
                            <ul className="scrolling-list">
                                <li><a href="#Swapping-on-FirstDex">Swapping on FirstDex  </a></li>
                                <li><a href="#Deposit/Withdrawl">Deposit/Withdrawl </a></li>
                                <li><a href="#KYC">KYC</a></li>
                                <li><a href="#Wallet-Safety-Measures">Wallet Safety Measures  </a></li>
                            </ul>
                            <div className="spacer-30" />
                            <h3 id="Swapping-on-FirstDex">Swapping on FirstDex</h3>
                            <div className="space-left">
                                <h5>1. Why do I need SOL to buy Raydium on the Solana blockchain? </h5>
                                <p>
                                    To swap on the Solana blockchain, you need SOL, the native cryptocurrency of the Solana network, to pay for transaction fees (also called gas fees). Think of SOL as a small amount of “fuel” required to complete each transaction on the blockchain.
                                </p>
                                <p>
                                    Similarly, ETH is the native currency of the Ethereum blockchain, and it is used to pay for transaction fees  on Etherium blockchain and BNB is the native currency of the Binance Smart Chain (BSC), and it is used to pay for transaction fees on Binance Smart Chain
                                </p>
                                <div className="spacer-30" />
                                <h5>2. How do I deposit SOL on my wallet? </h5>
                                <p>
                                    When you deposit INR in your wallet equivalent amount of SOL is credited within 2 minutes in your wallet. You can buy any token on Solona blockchain using SOL
                                </p>
                                <div className="spacer-30" />
                                <h5>3. How do I convert SOL to ETH to buy AAVE on Etherium blockchain? </h5>
                                <p>Since SOL is native to the Solana blockchain and AAVE is on Ethereum, you'll need to use cross-chain bridge functionality in FirstDex. </p>
                                <div className="spacer-30" />
                                <h5>4. Why can’t I swap 1.5 ETH for a token if I have exactly 1.5 ETH in my wallet? </h5>
                                <p>When you try to swap ETH for tokens on Etherium blockchain, you need to leave some ETH behind to cover the transaction’s gas fees. Gas fees are paid in ETH, and they can fluctuate depending on the network’s demand. For example, if you have 1.5 ETH, you might need to leave 0.01–0.03 ETH for the gas fee to complete the transaction. </p>
                                <p>• Tip: Always check the current gas prices on Ethereum before making a transaction, and make sure you have enough ETH left in your wallet to pay for gas. </p>
                                <div className="spacer-30" />
                                <h5>5. How can I verify that my swap was successful? </h5>
                                <p>After making a swap, you can verify the transaction in recent activities in FirstDex app. Link to explorers like Solscan for Solana, Etherscan for Ethereum, or BSCScan for Binance Smart Chain are mentioned for every transaction. Each transaction is recorded on the blockchain and can be traced using your transaction hash. </p>

                                <div className="spacer-10" />
                                <ul className="dot-list">
                                    <li><b>Solana:</b> Solscan</li>
                                    <li><b>Ethereum:</b> Etherscan</li>
                                    <li><b>Binance Smart Chain:</b> BSCScan</li>
                                </ul>
                                <div className="spacer-10" />

                                <h5>6. What if I don’t have enough ETH, SOL, or BNB to cover gas fees? </h5>
                                <p>If you don’t have enough of the required token for gas (ETH for Ethereum, SOL for Solana, or BNB for Binance Smart Chain), your transaction will fail. It’s important to always have a small balance of the network’s native token to ensure your transaction goes through. You can buy small amounts of ETH, SOL, or BNB on our platform or can directly transfer crypto to your wallet. </p>
                                <div className="spacer-30" />

                                <h5>7. Can I use tokens from other blockchains on FirstDex? </h5>
                                <p>
                                    Currently, FirstDex supports only Solana, Ethereum, and Binance Smart Chain. If you have tokens from other blockchains, you’ll need to use a bridge or swap service to convert them to a supported token before trading.
                                </p>
                                <div className="spacer-30" />

                                <h5>8. How do I connect my wallet to FirstDex? </h5>
                                <p>You can connect your wallet using popular wallet extensions like MetaMask or Trust Wallet, depending on the blockchain you want to interact with. Make sure your wallet is set to the correct network (Solana, Ethereum, or Binance Smart Chain) before connecting to our DEX. </p>
                                <div className="spacer-30" />
                            </div>
                            <h3 id="Deposit/Withdrawl">Deposit/Withdrawal </h3>
                            <div className="space-left">
                                <h5>1. Are their any limits on withdrawals? </h5>

                                <p>There are no limits on withdrawals. Whatever crypto you hold can be converted to INR anytime and withdrawn in full. All withdrawal requests are completed between 1hour – 24 hours. </p>
                                <div className="spacer-30" />

                                <h5>2. What are the different fee’s involved in using FirstDex? </h5>
                                <p>
                                    If you are depositing/Withdrawing through direct bank transfer there is no extra fee. However, when depositing through Razorpay, a 2% transaction fee will apply.
                                </p>
                                <p>
                                    FirstDex platform fee is 2% and will be charged on all deposits and withdrawals.
                                </p>
                                <p>
                                    Gas fee for transactions are very minimal for transactions done on Solona blockchain and Binance smart chain. However gas fee is high in Etherium blockchain.
                                </p>
                                <div className="spacer-30" />
                            </div>
                            <h3 id="KYC">KYC </h3>
                            <div className="space-left">
                                <h5>1. What are the main reasons for my KYC approval getting delayed? </h5>
                                <p>If your Aadhar has your mobile number tagged, KYC will be usually completed in 2 minutes but if it’s not tagged KYC approval will be slightly delayed.</p>
                                <p> Bank account registered with FirstDex is not active or name on the bank account is not matching name on the FirstDex account. </p>
                                <div className="spacer-30" />
                            </div>

                            <h3 id="Wallet-Safety-Measures">Wallet Safety Measures </h3>
                            <div className="space-left">
                                <h5>1. How do I keep my wallet safe? </h5>
                                <ul className="dot-list">
                                    <li>
                                        <b>Never Share It:</b> Your seed phrase (or recovery phrase) is the key to your wallet. Sharing it gives full access to your assets.
                                    </li><li>
                                        <b>Store It Securely:</b> Write it down on paper and store it in a safe place, away from digital devices. Avoid taking screenshots or saving it online.
                                    </li><li>
                                        <b>Don’t Enter It Anywhere:</b> Only use your seed phrase on the FirstDex official app.
                                    </li><li>
                                        <b>Biometric Login:</b> Use features like fingerprint or face recognition open your wallet.
                                    </li><li>
                                        <b>Avoid Phishing Links:</b> Double-check URLs. Scammers often create fake websites to steal wallet details.
                                    </li><li>
                                        <b>Ignore Random Messages:</b> Don’t respond to unsolicited messages offering help or asking for wallet details.
                                    </li><li>
                                        <b>Avoid Public Wi-Fi:</b> Use a VPN if you must access your wallet on an unsecured network.
                                    </li><li>
                                        <b>Small Test Transactions:</b> For large transfers, send a small amount first to ensure the details are correct.
                                    </li><li>
                                        <b>Stay Updated:</b> Follow trusted blockchain resources to learn about new security threats and updates.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}
export default Faq;

import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerHeader from "../Components/InnerHeader";
function PrivacyPolicy() {
  return (
    <>
      {/* Inner Header */}
      <InnerHeader />
      {/* Inner Header */}

      <Container className="inner-main" fluid>
     
          <Container>
            <Row>
              <Col xl="12" lg="12" className="inner-content">
                <h3>1. Information We Collect</h3>
                <p>
                  {" "}
                  To ensure a secure and efficient experience, we collect
                  minimal information from our users:
                </p>
                <h4> 1.1 Verification Data</h4>
                <p>
                  {" "}
                  To create an account and access our services, we require the
                  following verification data:
                </p>
                <ul className="dot-list">
                  <li>
                    <b> First and Last Name:</b> To identify you as a user of
                    our platform.
                  </li>
                  <li>
                    <b> Email Address:</b> For communication purposes, including
                    account verification and updates.
                  </li>
                  <li>
                    <b> Government ID:</b> A form of identification (e.g.,
                    passport or Identity Document) for account verification to
                    comply with regulatory requirements.
                  </li>
                </ul>

                <h4> 1.2 Transaction Data </h4>
                <p>
                  {" "}
                  We collect transaction data necessary for processing your
                  operations on the platform:
                </p>
                <ul className="dot-list">
                  <li>
                    <b>Wallet Addresses: </b>Your unique blockchain wallet
                    addresses used for transactions.
                  </li>
                  <li>
                    <b>Transaction Details:</b> Information about the
                    transactions you conduct, including timestamps and amounts.
                  </li>
                </ul>
                <h4> 1.3 Data We Do Not Collect </h4>
                <p> To respect your privacy, we do not collect:</p>

                <ul className="dot-list">
                  <li>
                    Bank account details, payment preferences, or financial
                    data.
                  </li>
                  <li>
                    Device and network information, including IP addresses or
                    device identifiers.
                  </li>
                </ul>

                <h3> 2. How We Use Your Information </h3>
                <p>Your information is processed for several key purposes:</p>
                <h4> 2.1 Facilitate Transactions </h4>
                <p>
                  {" "}
                  We use your data to perform various transactions securely on
                  our platform, including:
                </p>
                <ul className="dot-list">
                  <li>Same-chain swaps</li>
                  <li>Cross-chain swaps</li>
                  <li>Fiat on/off-ramping</li>
                </ul>
                <h4> 2.2 Verify Identity </h4>
                <p> We utilize your verification data to:</p>
                <ul className="dot-list">
                  <li>Ensure the security of our platform.</li>
                  <li>
                    Prevent fraudulent activity and comply with regulatory
                    requirements.
                  </li>
                </ul>

                <h4> 2.3 Improve User Experience </h4>
                <p>
                  {" "}
                  Your information helps us enhance our services by allowing us
                  to:
                </p>
                <ul className="dot-list">
                  <li>Address support requests efficiently.</li>
                  <li>
                    {" "}
                    Optimize platform functionality based on user feedback.
                  </li>
                </ul>

                <h3> 3. Data Protection </h3>
                <p>
                  {" "}
                  At FirstDex, we employ the highest security standards to
                  safeguard your data:
                </p>
                <h4> 3.1 Encryption </h4>
                <p>
                  {" "}
                  All personal and transaction data is encrypted during storage
                  and transmission using industry-standard encryption protocols.
                </p>
                <h4> 3.2 Authentication </h4>
                <p>
                  {" "}
                  We implement secure login procedures that include multi-factor
                  authentication (MFA) to enhance account security.
                </p>
                <h4> 3.3 Data Minimization </h4>
                <p>
                  {" "}
                  We adhere to the principle of data minimization by collecting
                  only the essential data required for our services and
                  retaining it for the shortest time necessary.
                </p>

                <h3> 4. Blockchain Transparency </h3>
                <p>
                  {" "}
                  All blockchain transactions are inherently public. While we do
                  not store personal data on the blockchain, certain transaction
                  metadata (e.g., wallet addresses, transaction hashes) is
                  visible to participants in the network. Users should be aware
                  that once transaction data is recorded on the blockchain, it
                  cannot be altered or deleted.
                </p>

                <h3> 5. Retention Policy </h3>
                <p>
                  {" "}
                  We retain verification data only as long as necessary for
                  specific purposes:
                </p>
                <ul className="dot-list">
                  <li>
                    <b>Compliance:</b> To meet applicable legal obligations.{" "}
                  </li>
                  <li>
                    <b>Account Functionality:</b> To support user account
                    operations.
                  </li>
                </ul>
                <p>
                  {" "}
                  Upon expiration of the retention period, we ensure that all
                  data is securely deleted or anonymized in accordance with best
                  practices.
                </p>

                <h3> 6. Your Rights </h3>
                <p>
                  {" "}
                  We respect your control over your personal data and provide
                  you with specific rights regarding its use:
                </p>
                <h4> 6.1 Access or Update Information </h4>
                <p>
                  {" "}
                  You have the right to access your personal information and
                  request modifications to your verification details if they are
                  inaccurate or incomplete.
                </p>
                <h4> 6.2 Request Deletion </h4>
                <p>
                  {" "}
                  You can request the deletion of your personal data at any
                  time; however, please note that certain legal constraints may
                  apply that prevent immediate deletion.
                </p>
                <h4> 6.3 Opt-Out Options </h4>
                <p>
                  {" "}
                  You can manage your preferences regarding notifications and
                  updates from us through your account settings.
                </p>

                <h3> 7. Cookies and Tracking Technologies </h3>
                <p>
                  {" "}
                  FirstDex may use cookies or similar tracking technologies to
                  enhance user experience and analyze usage patterns on our
                  platform. You can manage cookie preferences through your
                  browser settings.
                </p>

                <h3> 8. Third-Party Services </h3>
                <p>
                  {" "}
                  We may engage third-party services (such as analytics
                  providers) that may have access to user data under specific
                  conditions. These third parties are obligated to protect your
                  information in accordance with this Privacy Policy.
                </p>

                <h3> 9. International Data Transfers </h3>
                <p>
                  {" "}
                  If applicable, please note that your personal data may be
                  transferred internationally, including outside of your country
                  of residence. We take appropriate measures to ensure that such
                  transfers comply with relevant laws and that adequate
                  safeguards are in place to protect your information.
                </p>

                <h3> 10. Legal Compliance </h3>
                <p>
                  {" "}
                  FirstDex is committed to complying with relevant privacy laws
                  and regulations, including GDPR (General Data Protection
                  Regulation) and CCPA (California Consumer Privacy Act). Users
                  have specific rights under these regulations that we respect
                  and uphold.
                </p>

                <h3> 11. Data Breach Protocols </h3>
                <p>
                  {" "}
                  In the event of a data breach that affects your personal
                  information, we will promptly notify you in accordance with
                  applicable laws and take necessary steps to mitigate any
                  potential harm.
                </p>

                <h3> 12. User Education on Privacy Practices </h3>
                <p>
                  {" "}
                  We encourage our users to adopt best practices for enhancing
                  their privacy while using blockchain technology, such as
                  avoiding address reuse and employing strong passwords for
                  their accounts.
                </p>

                <h3> 13. Policy Updates </h3>
                <p>
                  {" "}
                  This Privacy Policy may be revised periodically as regulatory
                  or technological landscapes evolve. Significant changes will
                  be communicated directly via email or through app
                  notifications to ensure you remain informed about how we
                  handle your data.
                </p>

              </Col>
            </Row>
          </Container>
  
      </Container>
    </>
  );
}
export default PrivacyPolicy;
